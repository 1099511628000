$(document).ready(function() {
    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,

          fixedContentPos: false
        });

    $('.iframe-popup').magnificPopup({
           type: 'iframe'
     });


    $('.porfoliothumb img').each(function() {
        if( this.complete ) {
            resizeUi.call( this );
        } else {
            $(this).one('load', resizeUi);
        }
    });

    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-bars').removeClass('icon-cross');
        }else{
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-bars').addClass('icon-cross');
        }
    });
    /* END menu for tablets and mobiles*/

    $('.owl-slider').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText:['<i class="icon icon-arrow2 prev"></i>','<i class="icon icon-arrow"></i>'],
        items: 1,
        callbacks: true,
        autoplay:true,
        autoplayTimeout:4000
    });
});

$(function () {
    $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
} );


function resizeUi(){
    $( "a.porfoliothumb" ).each(function( index ) {
        $(this).find('span').css('height',parseInt($(this).find('img').outerHeight())).css('width',parseInt($(this).find('img').outerWidth())+1);
    });
}
